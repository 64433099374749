import React from 'react'

const BalieInfo = (props) => {
  return (
    <div role="alert" className='warning-alert mt-5'>
      {props.variant === 'antwerpen-2022' && (
        <span>Waarschuwing: Belangrijke boodschap voor de mandatarissen met hoofdinschrijving bij Balie Antwerpen: Vanaf 1/1/2023 zal het niet langer mogelijk zijn om mandaten toegekend vanaf 1/1/2023 via dit platform te verzekeren. Voor vragen kan u contact opnemen per mail via het adres <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a>.</span>
      )}
      {props.variant === 'antwerpen-2023' && (
        <span>Waarschuwing: Belangrijke boodschap voor de mandatarissen met hoofdinschrijving bij Balie Antwerpen: Vanaf 1/1/2023 is het niet langer mogelijk om mandaten toegekend vanaf 1/1/2023 via dit platform te verzekeren. Mandaten toegekend voor 1/1/2023 kunnen wel nog verzekerd worden via dit platform t.e.m. 1/4/2023. Voor vragen kan u contact opnemen per mail via het adres <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a>.</span>
      )}
      {props.variant === 'brussel-2024' && (
        <span>Waarschuwing: Belangrijke boodschap voor de mandatarissen met hoofdinschrijving bij Balie Brussel: Vanaf 01/01/2025 zal het niet langer mogelijk zijn om mandaten toegekend vanaf 01/01/2025 via dit platform te verzekeren. Voor vragen kan u contact opnemen per mail via het adres <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a>.</span>
      )}
      {props.variant === 'brussel-2025' && (
        <span>Waarschuwing: Belangrijke boodschap voor de mandatarissen met hoofdinschrijving bij Balie Brussel: Vanaf 01/01/2025 is het niet langer mogelijk om mandaten toegekend vanaf 01/01/2025 via dit platform te verzekeren. Mandaten toegekend voor 01/01/2025 kunnen wel nog verzekerd worden via dit platform tot en met 31/03/2025. Voor vragen kan u contact opnemen per mail via het adres <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a>.</span>
      )}
    </div>
  )
}

export default BalieInfo
