import React, { useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Jumbotron, Col, Container, Row } from 'react-bootstrap'
import { ProspectContext } from '../contexts/ProspectContext'
import { Helmet } from 'react-helmet'

import listDownloadsIcon from './../img/icon_list_downloads.svg'
import subscribeIcon from './../img/icon_subscribe.svg'
import backImage from './../img/go_back.svg'

import Plans from './Plans'
import ListDownloads from './ListDownloads'
import BalieInfo from './BalieInfo'

const PlansOrDownloads = () => {
  const [choice, setChoice] = useState(null)
  const { state } = useContext(ProspectContext)

  return (
    <div className='PlansOrDownloads'>
      <Helmet>
        <title>BA beroep mandaten Ond. Rb.</title>
      </Helmet>
      <Container>
        {!state.prospect.cases || state.prospect?.cases?.length === 0
          ? (
            <>
              <h1>Welkom op het digitaal<br />verzekeringsplatform van Vanbreda Risk &amp; Benefits.</h1>
              {(['antwerpen-2022', 'antwerpen-2023', 'brussel-2024', 'brussel-2025'].includes(state.prospect?.showBalieWarning)) && (
                <BalieInfo variant={state.prospect.showBalieWarning} />
              )}
              <p className='lead mt-5'>Maak uw keuze uit onderstaande producten</p>
              <Plans />
            </>
          )
          : (
            choice === null
              ? (
                <Row>
                  <Col className="text-center mb-5" md={12}>
                    <h1>Dit mandaat is al verzekerd. <br />Wat wil je doen?</h1>
                    {(['antwerpen-2022', 'antwerpen-2023', 'brussel-2024', 'brussel-2025'].includes(state.prospect?.showBalieWarning)) && (
                      <BalieInfo variant={state.prospect.showBalieWarning} />
                    )}
                  </Col>
                  <Col md={6}>
                    <Jumbotron className='text-center'>
                      <img className='mb-4' src={listDownloadsIcon} alt='Download certificates' />
                      <h3 className='mb-4'>Bekijk alle certificaten met betrekking tot dit mandaat.</h3>
                      <Button onClick={() => setChoice('download')}>Alle voorgaande certificaten</Button>
                    </Jumbotron>
                  </Col>

                  <Col md={6}>
                    <Jumbotron className='text-center'>
                      <img className='mb-4' src={subscribeIcon} alt='Payment success' />
                      <h3 className='mb-4'>Upgrade of verzeker iemand anders voor dit mandaat.</h3>
                      <Button onClick={() => setChoice('subscribe')}>Dit mandaat verzekereren</Button>
                    </Jumbotron>
                  </Col>
                </Row>)
              : (
                <>
                  {choice === 'download' && (
                    <>
                      <h1 className="mb-4" onClick={() => setChoice(null)} style={{ cursor: 'pointer' }}>
                        <a href={`/ui/prospect/${state.prospect.prospectId}`} style={{ marginRight: '1rem' }}><img src={backImage} alt='Ga Terug' /></a>
                        Alle voorgaande certificaten
                      </h1>
                      <ListDownloads />
                    </>
                  )}
                  {choice === 'subscribe' && (
                    <>
                      <h1 className="mb-4" onClick={() => setChoice(null)} style={{ cursor: 'pointer' }}>
                        <a href={`/ui/prospect/${state.prospect.prospectId}`} style={{ marginRight: '1rem' }}><img src={backImage} alt='Ga Terug' /></a>
                        Dit mandaat verzekereren
                      </h1>
                      <Plans />
                    </>
                  )}
                </>
              )
          )
        }
      </Container>
    </div>
  )
}

export default withRouter(PlansOrDownloads)
