import React from 'react'
import { Navbar, Container, Col } from 'react-bootstrap'
import logo from '../img/vrb.svg'

const Header = () => {
  return (
    <Navbar bg='primary' variant='dark'>
      <Container>
        <Col>
          <Navbar.Brand>
            <img
              alt=''
              src={logo}
              width='103'
              height='46'
              className='logo-image d-inline-block align-top'
              style={{ marginRight: 20 }}
            />{' '}
            <span className='logo-title d-inline-flex mt-2'>Burgerlijke Aansprakelijkheid mandaten ondernemingsrechtbanken</span>
          </Navbar.Brand>
        </Col>
      </Container>
    </Navbar>
  )
}

export default Header
