import React, { useState, useContext, useEffect } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { ProspectContext } from './../contexts/ProspectContext'
import Loader from './Loader'
import { prepareBancontactPayment, proceedBancontactPayment } from '../utils/payment'

const BancontactForm = ({ processingBancontactPayment, handleError, handleSuccess, handleLoading, loading }) => {
  const { state } = useContext(ProspectContext)
  const [complete, setComplete] = useState(false)

  const payload = {
    PaymentMethod: 'bancontact',
    ProspectId: state.prospect.prospectId,
    selectedPlan: state.selectedPlan.title
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    const form = evt.currentTarget
    if (form.checkValidity() === false) {
      evt.stopPropagation()
    } else {
      const firstName = form.elements.firstName.value
      const lastName = form.elements.lastName.value
      payload.AccountHolder = [firstName, lastName].join(' ')
      handleLoading(true)
      prepareBancontactPayment(payload)
        .catch(() => {
          handleError(true)
        })
    }
    setComplete(true)
  }

  useEffect(() => {
    if (processingBancontactPayment.status) {
      handleLoading(true)
      payload.PaymentSource = processingBancontactPayment.source
      proceedBancontactPayment(payload).then((certificateUrl) => {
        handleSuccess({
          completed: true,
          certificate: certificateUrl
        })
      }).catch(() => {
        handleError(true)
      }).finally(() => {
        handleLoading(false)
      })
    }
  }, [state])

  return (
    <>
      {loading && (
        <Loader loadingText='Betaling verwerken…' />
      )}
      <div className='BancontactForm' {...(loading ? { style: { display: 'none' } } : {})}>
        <Form noValidate validated={complete} onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md='6' controlId='firstName'>
              <Form.Label>Voornaam</Form.Label>
              <Form.Control type='text' name='firstName' defaultValue={state.prospect.model.firstName} required />
              <Form.Control.Feedback type='invalid'>Voornaam veld is verplicht</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md='6' controlId='lastName'>
              <Form.Label>Naam</Form.Label>
              <Form.Control type='text' name='lastName' defaultValue={state.prospect.model.lastName} required />
              <Form.Control.Feedback type='invalid'>Naam veld is verplicht</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Button type='submit'>Onderschrijven</Button>
        </Form>
      </div>
    </>
  )
}

export default BancontactForm
