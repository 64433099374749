import React, { useState } from 'react'
import { Form, Button, Col, InputGroup, Alert, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import { createReport } from './../utils/apiCall'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const ReportSearch = ({ loading, setLoading }) => {
  const [statusCode, setStatusCode] = useState()

  const [globalValues, setGlobalValues] = useState({
    Password: '',
    From: new Date(),
    To: new Date()
  })

  const runCreateReport = (payload) => {
    setLoading(true)
    return createReport(payload)
      .then(response => {
        console.log(response)
        setStatusCode(response.status)

        if (response.status === 400) {
          console.log(response.data)
          return response.data
        } else {
          return null
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const validationSchema = yup.object().shape({
    Password: yup.string()
      .required('Password is required.'),
    From: yup.date()
      .required('From date is required.'),
    To: yup.date()
      .required('To date is required.'),
  })

  const DateFormControl = React.forwardRef((props, ref) => {
    return (
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={props.selected}
        onChange={props.onChange}
        minDate={props.minDate}
        maxDate={props.maxDate}
        value={props.value}
        className='form-control'
        wrapperClassName='form-control'
      />
    )
  })

  return (
    <div className='reportWrapper' {...(loading ? { style: { display: 'none' } } : {})}>
      <h1 className='mb-4'>Report</h1>
      {statusCode === 429 && (
        <Alert variant='danger'>
          You have requested the report too many times. Please try again later.
        </Alert>
      )}
      {statusCode === 200 && (
        <Alert variant='success'>
          Your report for the period {moment(globalValues.From).utc(true).format('DD/MM/YYYY')} - {moment(globalValues.To).utc(true).format('DD/MM/YYYY')} will be generated shortly and sent to you by email.
        </Alert>
      )}
      {statusCode === 403 && (
        <Alert variant='danger'>
          You are not authorized to access this feature. Please provide a valid password.
        </Alert>
      )}
      <Formik
        initialValues={globalValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm, setFieldError }) => {
          setSubmitting(true)

          const payload = { ...values }
          setGlobalValues(values)
          setStatusCode()

          // Call creactProspect
          runCreateReport({
            ...payload,
            From: moment(payload.From, 'DD/MM/YYYY').utc(true).format('YYYY-MM-DD').toString(),
            To: moment(payload.To, 'DD/MM/YYYY').utc(true).format('YYYY-MM-DD').toString()
          })
            .then(errors => {
              if (errors && errors.validationState) {
                errors.validationState.forEach(({ key, value }) => setFieldError(key, value))
              }
            })
            .finally(() => setSubmitting(false))
        }}
      >
        {(
          {
            setValues,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }
        ) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md={3} controlId="Password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Password"
                    name="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.Password}
                    autoComplete='off'
                    className={touched.Password && errors.Password ? 'has-error' : null}
                  />
                </Form.Group>

                <Form.Group as={Col} md={3} controlId="From">
                  <Form.Label>Start date</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">From</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as={DateFormControl}
                      placeholder="Start Date"
                      aria-describedby="inputGroupPrepend"
                      name="From"
                      selected={values.From}
                      minDate={new Date('1971-01-01')}
                      maxDate={new Date()}
                      onChange={date => setValues({ ...values, From: date })}
                      onBlur={handleBlur}
                      className={touched.From && errors.From ? 'has-error' : null}
                      required
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group as={Col} md={3} controlId="To">
                  <Form.Label>End date</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">To</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as={DateFormControl}
                      placeholder="End Date"
                      aria-describedby="inputGroupPrepend"
                      name="To"
                      selected={values.To}
                      minDate={values.From}
                      maxDate={new Date()}
                      onChange={date => setValues({ ...values, To: date })}
                      onBlur={handleBlur}
                      className={touched.To && errors.To ? 'has-error' : null}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <Col md={3}>
                  <Button type='submit' style={{ width: '100%' }} disabled={isSubmitting || loading}>Get report</Button>
                </Col>
              </Form.Row>
              <Row>
                <Col md={3}>
                  {touched.Password && errors.Password ? (
                    <p className='error-message'><small>{errors.Password}</small></p>
                  ) : null}
                </Col>
                <Col md={3}>
                  {touched.From && errors.From ? (
                    <p className='error-message'><small>{errors.From}</small></p>
                  ) : null}
                </Col>
                <Col md={3}>
                  {touched.To && errors.To ? (
                    <p className='error-message'><small>{errors.To}</small></p>
                  ) : null}
                </Col>
              </Row>
            </Form>
          )}
      </Formik>
    </div>
  )
}

export default ReportSearch
