import React from 'react'
import successImage from './../img/payment_success.svg'
import downloadImage from './../img/icon_download.svg'
import { Button, Container, Jumbotron } from 'react-bootstrap'

const DownloadMessage = ({ certificate, title }) => {

  return (
    <Container className='DownloadMessage'>
      <Jumbotron className='text-center'>
        <img className='mb-4' src={successImage} alt='Payment success' />
        {title ? (
          <h1 className='mb-5'>{title}</h1>
        ) : (
          <h1 className='mb-5'>Dit mandaat is al verzekerd</h1>
        )}
        <Button href={certificate}><img src={downloadImage} alt='Download' style={{ marginRight: '.5rem' }} />Download uw certificaat</Button>
      </Jumbotron>
    </Container>
  )
}

export default DownloadMessage
