import React from 'react'
import errorImage from './../img/payment_error.svg'
import { Jumbotron, Container } from 'react-bootstrap'

const BrusselInfoB = () => {
  return (
    <Container>
      <Jumbotron className='text-center'>
        <img className='mb-4' src={errorImage} alt='error' />
        <p>Error: Belangrijke boodschap voor de mandatarissen met hoofdinschrijving bij Balie Brussel: Vanaf 01/04/2025 is het niet langer mogelijk om mandaten via dit platform te verzekeren, ook niet als ze toegekend werden voor 01/01/2025.</p>
        <p>Voor vragen kan u contact opnemen per mail via het adres <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a>.</p>
      </Jumbotron>
    </Container>
  )
}

export default BrusselInfoB
