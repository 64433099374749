import React from 'react'
import { Container } from 'react-bootstrap'

const Privacy = () => {
  return (
    <Container className='legal-page'>
      <h1>Privacyverklaring Vanbreda Risk &amp; Benefits</h1>
      <p className='h4'>Al jaren dragen de ondernemingen van de Vanbreda-groep privacy hoog in het vaandel. Wij verwerken de persoonsgegevens van elke natuurlijke persoon met zorg en op een wijze die rekening houdt met de geldende wetgeving, dit ongeacht of deze natuurlijke persoon een (potentiële) verzekeringsnemer, verzekerde, benadeelde, begunstigde, contactpersoon bij een bedrijf, getuige, expert, … is.</p>
      <p className='h4'>Deze verklaring legt uit waarom Vanbreda persoonsgegevens verzamelt, wat wij ermee doen en welke rechten u heeft. Vanbreda kan deze privacyverklaring aanpassen. De meest recente versie vind je altijd op <a href='https://www.vanbreda.be/nl/privacy/'>https://www.vanbreda.be/nl/privacy/</a></p>

      <h2>1. Wie is de Vanbreda-groep?</h2>
      <p>Vanbreda Risk &amp; Benefits is de moedermaatschappij met daaronder een geheel van vennootschappen die zich bezighouden met verzekeringsmakelaars- en diverse verzekeringsactiviteiten, adviesverlening, vermogensbeheer, risk management en claimshandling en/of activiteiten die in het verlengde hiervan liggen. Elke onderneming uit de groep is erkend als onafhankelijke verzekeringsmakelaar.</p>
      <p>Vanbreda Risk &amp; Benefits in België bestaat uit:</p>
      <ul>
        <li>Vanbreda Risk &amp; Benefits – FSMA nr. 13718 A-R (BE 0404.055.676)</li>
        <li>Vanbreda Ausloos – FSMA nr. 47833 A (BE 0474.182.421);</li>
        <li>Vanbreda Cornelis – FSMA nr. 12565 A (BE 0425.054.196);</li>
        <li>Vanbreda Credinco – FSMA nr. 042518 A (BE 0462.175.702);</li>
        <li>Vanbreda Geerts – FSMA nr. 044401 A (BE 0466.513.679);</li>
        <li>Vanbreda Huysmans – FSMA nr. 067446 A (BE 0478.505.552);</li>
        <li>Vanbreda Missinne – FSMA nr. 013584 A (BE 0426.071.906).</li>
      </ul>
      <p>In Nederland wordt Vanbreda Risk &amp; Benefits vertegenwoordigd door <a href='https://www.vanbredanl.com' rel='noopener noreferrer' target='_blank'>Vanbreda Risk &amp; Benefits Nederland</a> en in Luxemburg door <a href='http://www.vanbredalang.lu' rel='noopener noreferrer' target='_blank'>Vanbreda &amp; Lang</a>.</p>
      <p>Vanbreda Risk &amp; Benefits heeft nauwe banden met de verzekeringsmaatschappij <a href='https://www.justitia.be' rel='noopener noreferrer' target='_blank'>Justitia</a>. Justitia is gespecialiseerd in het verzekeren van Employee Benefits-plannen die door Vanbreda Risk &amp; Benefits op maat van de onderneming-verzekeringsnemer worden uitgewerkt.</p>
      <p>Op onze <a href='https://www.vanbreda.be/nl/contact/' rel='noopener noreferrer' target='_blank'>contactpagina</a> vindt u alle contact- en identificatiegegevens van de verschillende Vanbreda-ondernemingen.</p>
      <p>De verwerkingsverantwoordelijke van uw persoonsgegevens is de Vanbreda-onderneming die deze verzameld heeft. Naar deze verwerkingsverantwoordelijke wordt in deze verklaring verwezen als Vanbreda.</p>

      <h2>2. Welke persoonsgegevens verwerken wij?</h2>
      <p>Afhankelijk van de situatie verwerken wij volgende persoonsgegevens: uw identiteitsgegevens, uw contactgegevens, gezondheidsgegevens, financiële gegevens, rijksregisternummer, … Wij verwerken deze persoonsgegevens enkel om één van de redenen opgesomd onder punt 4.</p>

      <h2>3. Uw rechten</h2>
      <p><strong>3.1 U kunt uw persoonsgegevens inkijken</strong></p>
      <p>Als u wilt weten of en welke persoonsgegevens Vanbreda van u verwerkt, kan u inzage vragen. Vanbreda zal u dan een zo volledig mogelijk overzicht bezorgen van de persoonsgegevens die zij in haar actieve bestanden verwerkt. Gezien de grote hoeveelheid persoonsgegevens die door Vanbreda verwerkt worden, verzoeken we om te preciseren op welke informatie of welke verwerkingsactiviteiten het verzoek betrekking heeft. Persoonsgegevens die enkel voorkomen in back-up- of logbestanden of niet rechtstreeks aan u gelinkt kunnen worden, zullen niet worden opgenomen. Gezien de gevoeligheid van gezondheidsgegevens worden deze niet standaard verstuurd. Hiervoor vragen we om aan te geven naar welke arts we deze kunnen sturen zodat deze daar ter uwer beschikking zijn.</p>
      <p><strong>3.2 U kunt uw persoonsgegevens laten verbeteren</strong></p>
      <p>
        Als blijkt dat de persoonsgegevens die wij van u verwerken onvolledig of niet (meer) juist zijn, kunt u deze (laten) aanpassen of vervolledigen.<br /><br />
        <strong>3.3 U kan uw persoonsgegevens soms laten wissen</strong>
      </p>
      <p>Uw persoonsgegevens worden na verloop van tijd automatisch gewist. Meer uitleg hierover vindt u onder punt 6. Als u denkt dat uw persoonsgegevens ten onrechte worden verwerkt, kunt u vragen dat Vanbreda deze vroeger wist.</p>
      <p>3.4 U kunt bezwaar aantekenen tegen bepaald gebruik van uw persoonsgegevens</p>
      <p>Het kan zijn dat u, door een specifieke situatie waarin u zich bevindt, niet akkoord kan gaan met een verwerking op basis van de rechtvaardige belangen van Vanbreda. In dat geval kan u bezwaar aantekenen tegen de verwerking van uw persoonsgegevens en zal Vanbreda uw specifieke en haar belangen afwegen. Enkel als er dwingende gerechtvaardigde gronden zijn die zwaarder doorwegen dan uw belangen (vb. fraudebestrijding) of in het kader van een (toekomstige) rechtsvordering, zal Vanbreda uw persoonsgegevens verder verwerken.</p>
      <p>Als u bezwaar maakt tegen de verwerking van uw persoonsgegevens voor direct marketing, zal Vanbreda de verwerking voor dit doeleinde beëindigen.</p>
      <p><strong>3.5 U kunt zich verzetten tegen bepaald gebruik van uw persoonsgegevens</strong></p>
      <p>U kunt Vanbreda vragen om de verwerking van uw persoonsgegevens te beperken als er bijvoorbeeld tijd nodig is om de juistheid van uw persoonsgegevens te controleren, als de verwerking onrechtmatig is, als Vanbreda deze persoonsgegevens niet meer nodig heeft ( maar u wel in het kader van een rechtsvordering) of als u bezwaar heeft gemaakt tegen een verwerking en er nog een belangenafweging moet plaatsvinden.</p>
      <p><strong>3.6 U kunt vragen om uw persoonsgegevens over te dragen aan een andere partij</strong></p>
      <p>Voor de persoonsgegevens die u zelf aan Vanbreda verstrekt heeft, en die met uw toestemming of op basis van een overeenkomst en via een geautomatiseerd procedé verwerkt worden, kunt u vragen dat ze overgedragen worden aan uzelf of iemand anders.</p>
      <p><strong>3.7 U kunt soms weigeren dat er besluiten genomen worden op basis van een geautomatiseerde verwerking</strong></p>
      <p>In sommige van onze processen worden besluiten genomen zonder menselijke tussenkomst. Een voorbeeld hiervan is de terugbetaling van een schade waarvoor het in de specifieke situatie ook voor een computer duidelijk is dat de terugbetaling vanzelfsprekend is. Het bestaan van dergelijke geautomatiseerde besluitvoering en de onderliggende logica worden bij het proces zelf meegegeven. U mag uw contactpersoon bij Vanbreda steeds contacteren als u vragen heeft bij deze geautomatiseerde besluitvoering of als u zich niet kan vinden in een besluit en hierbij toelichting wenst te geven.</p>
      <p><strong>3.8 U kunt uw toestemming intrekken</strong></p>
      <p>Voor de persoonsgegevens die Vanbreda op basis van uw toestemming verwerkt, kan u te allen tijde uw toestemming intrekken. Als u uw toestemming intrekt, kan het zijn dat Vanbreda bepaalde diensten/services niet meer kan leveren.</p>
      <p><strong>3.9 Hoe kan u deze rechten uitoefenen?</strong></p>
      <p>Als u één van deze rechten wenst uit te oefenen is het belangrijk dat u:</p>
      <ul>
        <li>uw vraag aan Vanbreda duidelijk formuleert (o.a. over welke informatie of welke activiteit uw verzoek gaat, welk type verzekering, uw cliëntennummer,…);</li>
        <li>zich identificeert (vb. via de beveiligde online platformen of een kopie van de identiteitskaart).</li>
      </ul>
      <p>Naargelang de omvang en de impact van uw vraag, kan Vanbreda bijkomende informatie vragen.</p>
      <p>Uw vraag richt u het best aan uw contactpersoon bij Vanbreda.</p>
      <p>Voor uw vragen of klachten over de uitoefening van uw rechten kunt u ook terecht bij de Data Protection Officer van Vanbreda: via ons formulier, per e-mail naar <a href='mailto:dpo@vanbreda.be' rel='noopener noreferrer' target='_blank'>dpo@vanbreda.be</a> of per post naar Vanbreda Risk &amp; Benefits NV, t.a.v. Data Protection Officer, Plantin en Moretuslei 297, 2140 Antwerpen.</p>
      <p>Meer informatie over de wetgeving rond de bescherming van persoonsgegevens vindt u terug op <a href='https://www.gegevensbeschermingsautoriteit.be' rel='noopener noreferrer' target='_blank'>www.gegevensbeschermingsautoriteit.be</a>, de website van de Belgische Gegevensbeschermingsautoriteit. U kunt daar ook klacht indienen.</p>

      <h2>4. Waarom verwerken wij uw persoonsgegevens?</h2>
      <p><strong>4.1 Om te voldoen aan wettelijke verplichtingen</strong></p>
      <p>Vanbreda heeft bepaalde persoonsgegevens nodig om te voldoen aan haar wettelijke verplichtingen. Indien zij niet over deze gegevens kan beschikken, kan zij geen overeenkomst met u sluiten, verderzetten of een verrichting uitvoeren die door u of een derde in uw voordeel gevraagd werd.</p>
      <p>Wettelijke bepalingen die Vanbreda er toe aanzetten uw persoonsgegevens te verwerken zijn onder andere:</p>
      <ul>
        <li>De verzekeringswetgeving met in het bijzonder de wet van 4 april 2014 en de wet van 2 augustus 2002 betreffende het toezicht op de financiële sector en de financiële diensten;</li>
        <li>De antiwitwaswetgeving met in het bijzonder de wet van 18 september 2017;</li>
        <li>De sanctiewetgeving met in het bijzonder de Europese verordening 2580/2001/EG;</li>
        <li>Boekhoudkundige en fiscale verplichtingen.</li>
      </ul>
      <p><strong>4.2 Om de overeenkomst te kunnen sluiten en uitvoeren</strong></p>
      <p>Sommige persoonsgegevens zijn nodig om de bemiddelingsovereenkomst te kunnen sluiten of om de service te kunnen leveren die u van Vanbreda mag verwachten bij de uitvoering van de overeenkomst.</p>
      <p>Tot de service van Vanbreda behoort onder andere het adviseren, aanbieden en voorstellen van verzekeringsovereenkomsten, maar ook het verrichten van voorbereidend werk tot het sluiten van verzekeringsovereenkomsten en het sluiten van verzekeringsovereenkomsten zelf. Ten slotte zal Vanbreda u ook assisteren bij het beheer en de uitvoering van de verzekeringsovereenkomsten. Zij zorgt mee voor de schadeaangiften en zal, indien nodig, experten aanstellen of in contact treden met andere betrokken partijen en aan hen uw persoonsgegevens overmaken.</p>
      <p>Als u gebruik wenst te maken van een derdebetalerssysteem, zal Vanbreda ook hiervoor persoonsgegevens moeten verwerken.</p>
      <p><strong>4.3 Voor de goede werking van onze diensten</strong></p>
      <p>Vanbreda zal uw persoonsgegevens ook verwerken op grond van haar gerechtvaardigd belang.</p>
      <p>Verwerkingen die op deze grond gebeuren, kaderen onder andere in:</p>
      <ul>
        <li>Cliënteelmanagement;</li>
        <li>Centrale administratie binnen Vanbreda Risk &amp; Benefits;</li>
        <li>Algemene sturing van de onderneming, inclusief rendabiliteitsopvolging;</li>
        <li>Risicobeheer, fraudebestrijding &amp; compliance;</li>
        <li>Juridische ondersteuning en verdediging van onze rechten;</li>
        <li>Controle en verbetering van de kwaliteit van de dienstverlening;</li>
        <li>Interne audit;</li>
        <li>IT-ondersteuning ten behoeve van andere doeleinden;(vb. gebruik van pseudo-anonieme gegevens bij het testen van programma’s, het zoeken naar oorzaken en oplossen van IT-problemen,…);</li>
        <li>Netwerk- en informatiebeveiliging, inclusief toegangscontrole;</li>
        <li>Studies en statistiek;</li>
        <li>Analyse van gebruik van website, apps .. met het oog op verbeteren van onze producten en diensten;</li>
        <li>Direct marketing aan bestaand cliënteel (commerciële mailings, uitnodigingen events en seminaries, versturen van enquêtes,…).</li>
      </ul>
      <p>
        Bij deze verwerkingen zal Vanbreda telkens haar eigen belang afwegen tegen uw rechten en redelijke verwachtingen. Indien u niet akkoord bent met één van bovengenoemde verwerkingen, kan u zoals omschreven in punt 3 hiertegen bezwaar aantekenen.<br /><br />
        <strong>4.4 Voor de goede werking van diensten van onze cliënten en verzekeraars</strong>
      </p>
      <p>Vanbreda zal uw persoonsgegevens ook verwerken op grond van gerechtvaardigde belangen van derde partijen zoals cliënten, werkgevers en verzekeraars. Dit zal enkel gebeuren wanneer het belang van de andere partij zwaarder doorweegt dan de impact op uw rechten en redelijke verwachtingen.</p>
      <p>Verwerkingen die op deze grond gebeuren, kaderen onder andere in:</p>
      <ul>
        <li>Rapportering van statistieken aan cliënten en werkgevers, bijvoorbeeld om werkomstandigheden te kunnen verbeteren, gerichte opleiding te kunnen voorzien, …;</li>
        <li>Opvragen en vergelijken van verzekeringsoffertes;</li>
        <li>Het voorzien van en aansluiten bij verzekeringen (bv. arbeidsongevallenverzekering).</li>
      </ul>
      <p>Waar mogelijk worden deze gegevens op een anonieme basis gecommuniceerd met de betrokken partijen.</p>
      <p><strong>4.5 Om aan direct marketing te doen</strong></p>
      <p>Vanbreda wenst u producten en diensten aan te bieden die relevant zijn voor u. Daarnaast zien we het als onze taak om u te wijzen op nieuwe bedrijfsrisico’s die bijvoorbeeld de continuïteit van uw onderneming in gevaar kunnen brengen.</p>
      <p>Om u zo goed mogelijk te adviseren, maakt Vanbreda gebruik van de gegevens die het ter haar beschikking heeft. Vanbreda maakt gebruik van uw persoonsgegevens om aan direct marketing te doen. Door uw persoonsgegevens te analyseren, geniet u van een aanpak op maat en kan Vanbreda u gepersonaliseerde voorstellen doen. Indien u dit liever niet heeft, kan u dit ons steeds laten weten.</p>
      <p><strong>4.6 Omdat u ons hiervoor toestemming gaf</strong></p>
      <p>Bepaalde persoonsgegevens worden alleen verwerkt omdat u Vanbreda hiervoor toestemming heeft gegevens. Deze toestemming kan u steeds en zonder dit te moeten motiveren, intrekken.</p>
      <p>De intrekking betekent dat deze verwerkingen op basis van toestemming in de toekomst niet meer mogelijk zijn. De intrekking van uw toestemming heeft niet tot gevolg dat verwerkingen die gebeurd zijn op basis van uw toestemming plots niet meer geldig zijn.</p>

      <h2>5. Van wie krijgen wij uw persoonsgegevens?</h2>
      <p>Uw persoonsgegevens kunnen op verschillende manieren tot bij Vanbreda komen:</p>
      <ol>
        <li>U heeft deze zelf, bij aanvang of tijdens de samenwerking, aan ons meegedeeld;</li>
        <li>Vanbreda ontving uw persoonsgegevens van iemand uit uw (familiale) omgeving;</li>
        <li>Vanbreda ontving uw persoonsgegevens in het kader van de uitvoering van een overeenkomst met uw werkgever waarbij u een verzekerde/begunstigde/contactpersoon bent;</li>
        <li>Vanbreda vond deze persoonsgegevens in publieke bronnen terug (vb. Belgisch Staatsblad, LinkedIn,…);</li>
        <li>Vanbreda kocht deze aan bij derde partijen, vb. prospectiedatabank waar u uw toestemming aan heeft gegeven;</li>
        <li>Iemand anders heeft Vanbreda uw persoonsgegevens bezorgd in het kader van de uitvoering van een verzekeringsovereenkomst of het beheer van een schadegeval;</li>
        <li>Iemand anders heeft Vanbreda uw persoonsgegevens bezorgd in het kader van een overeenkomst die Vanbreda met deze derde heeft (vb. Ziekenhuisfactuur die via AssurCard rechtstreeks naar Vanbreda gaat);</li>
        <li>Op een onrechtstreekse wijze (bijvoorbeeld door het surfen naar onze website).</li>
      </ol>
      <p>Als u Vanbreda persoonsgegevens bezorgt van iemand anders, is het belangrijk dat u deze persoon daarover informeert en verwijst naar deze Privacy-verklaring.</p>

      <h2>6. Aan wie bezorgen wij uw persoonsgegevens?</h2>
      <p><strong>6.1 Vanbreda Risk &amp; Benefits</strong></p>
      <p>Uw persoonsgegevens kunnen uitgewisseld worden tussen de ondernemingen van Vanbreda Risk &amp; Benefits. Op die wijze heeft elke Vanbreda-onderneming zicht op de klantenrelatie en hoeft u ons niet elke keer dezelfde informatie te bezorgen.</p>
      <p>Dit geldt vanzelfsprekend niet voor uw medische gegevens. Deze worden enkel verwerkt door de onderneming waarbinnen ze verzameld werden.</p>
      <p>
        Binnen Vanbreda Risk &amp; Benefits hebben enkel de personen die de informatie nodig hebben voor de verwerking toegang tot uw persoonsgegevens. Tot welke gegevens zij toegang hebben, hangt af van de reden waarom zij deze toegang nodig hebben.<br /><br />
        <strong>6.2 Anderen </strong>
      </p>
      <p>Vanbreda kan uw persoonsgegevens bezorgen aan partijen die betrokken zijn bij de uitvoering van een overeenkomst of polis. Dit zijn bijvoorbeeld (potentiële) verzekeraars, de contactpersoon bij een bedrijf, (medische) experten en consultants, bank, mutualiteit,…</p>
      <p>
        Uw gezondheidsgegevens worden met extra zorg behandeld. Om geen onnodige gegevens te verwerken, vragen we om de afspraken bij de verzekeringspolis te respecteren. Zo wordt in sommige omstandigheden gevraagd om medische informatie rechtstreeks naar de raadsgeneesheer van de verzekeringsmaatschappij te sturen.<br />
        Als Vanbreda deze gegevens aan een derde meedeelt (vb. verzekeraar of uw huisarts), kadert dit enkel in de uitvoering van een overeenkomst of uitbetaling van een schadegeval.
      </p>
      <p>Vanbreda kan uw persoonsgegevens ook bezorgen aan partijen die betrokken zijn bij de afhandeling van een schadegeval. Dit zijn bijvoorbeeld de (verzekeraar van de andere) partij, (medische) experten en consultants, bank …</p>
      <p>Om haar dienstverlening optimaal te laten verlopen, maakt Vanbreda gebruik van een aantal leveranciers en onderaannemers. Om hun taken correct uit te voeren, kan het zijn dat zij toegang hebben tot uw persoonsgegevens. Door met hen een verwerkersovereenkomst te sluiten, legt Vanbreda zeer duidelijke krijtlijnen op waarvoor en waarbinnen uw persoonsgegevens verwerkt mogen worden. Daarnaast legt Vanbreda ook de nodige beveiligingsnormen op.</p>
      <p>Als Vanbreda hiertoe wettelijk verplicht is, zal zij uw persoonsgegevens delen met de bevoegde overheidsinstanties.</p>
      <p>Belangrijk: Vanbreda verkoopt uw persoonsgegevens niet!</p>
      <p>Vanbreda streeft ernaar om uw persoonsgegevens zoveel mogelijk binnen de Europese Economische Ruimte te verwerken, tenzij dit strikt noodzakelijk is voor de uitvoering van de overeenkomst (bv. contact met een lokale expert voor schade in het buitenland). Indien dit niet het geval is, zal Vanbreda u hierover informeren.</p>
      <p>In het kader van marketing-acties worden gerichte webpagina’s aangeboden om contactgegevens op te vragen. Deze gegevens worden via een extern platform (Unbounce) verwerkt, waarbij er mogelijks gegevens via de Verenigde Staten van Amerika verzonden worden. Hierbij zorgt het EU-U.S. Privacy Shield telkens voor de gepaste waarborgen.</p>

      <h2>7. Automatische besluitvorming</h2>
      <p>Vanbreda maakt in sommige processen gebruik van automatische verwerking van gegevens om de snelheid, betrouwbaarheid en efficiëntie van haar dienstverlening te verhogen. In beperkte gevallen (zie hieronder) is er een automatische besluitvorming waaraan voor de verzekerde gevolgen verbonden zijn. Deze processen zijn onderworpen aan een manueel nazicht op steekproefbasis. U heeft steeds het recht om voor deze processen een menselijke tussenkomst te vragen, uw visie te geven of het geautomatiseerde besluit aan te vechten. Contacteer hiervoor de uw contactpersoon of de Data Protection Officer.</p>
      <p>
        <img src='https://www.vanbreda.be/vrb-custom/uploads/2018/09/tabel_privacy.jpg' alt='' />
      </p>
      <h2>8. Hoe lang bewaren wij uw persoonsgegevens?</h2>
      <p>
        Vanbreda zal uw persoonsgegevens enkel bewaren zolang Vanbreda deze gegevens nodig heeft voor de uitvoering van haar taken en behartiging van haar belangen.<br />
        In onze actieve systemen vernietigen wij uw gegevens ten laatste na de verjaring van de aansprakelijkheidstermijn voor potentiële klachten.
      </p>
      <p>In onze back-upsystemen worden uw gegevens bewaard tot deze uit de aard van het bewaringssysteem zelf verwijderd worden.Wanneer een back-up gebruikt zou moeten worden, worden eerst de gegevens die niet meer nodig zijn voor de beschreven doeleinden verwijderd uit de actieve systemen.</p>

      <h2>9. Waarom gebruiken wij Google Analytics?</h2>
      <p>Vanbreda gebruikt Google Analytics om gegevens over het gebruik van de website te verzamelen en te verwerken in statistieken en gedetailleerde rapporten. Deze informatie wordt verzameld aan de hand van cookies met als doel de website te optimaliseren in functie van gebruikerspatronen.</p>
      <p>De informatie die wordt verzameld, wordt rechtstreeks gestuurd naar de servers van Google in de Verenigde Staten. Deze informatie wordt tevens daar opgeslagen. Om te vermijden dat data kunnen worden herleid naar specifieke gebruikers, wordt het IP-adres van de gebruiker doorgestuurd naar Google en daar geanonimiseerd alvorens het wordt gebruikt. Deze (geanonimiseerde) IP-adressen worden nooit samen verwerkt met andere gegevens van de gebruiker. Op die manier worden enkel data verzameld van anonieme groepen, en niet van individuele gebruikers.</p>
      <p>Bovendien verzamelt Vanbreda via Google Analytics geen demografische gegevens van haar gebruikers, en worden er geen bijkomende gegevens verzameld voor advertentiedoeleinden. Daarbij worden er geen User ID’s bijgehouden, en wordt er nergens persoonlijk identificeerbare informatie weergegeven in de URL’s van de website.</p>
      <p>Google treedt op als verwerker van onze data, en in functie van deze rol werd een verwerkersovereenkomst afgesloten met Google. Er werd Google geen toestemming verleend om de data van de website van Vanbreda te gebruiken voor eigen doeleinden, zoals advertenties tonen.</p>
      <p>De data die Google Analytics verzamelt zijn dus anoniem en worden enkel gebruikt om de gebruikservaring van de website zo goed mogelijk te maken. Informatie die wij verzamelen:</p>
      <ul>
        <li>Titel &amp; URL van de door de gebruiker bezochte pagina’s;</li>
        <li>Informatie over browser, besturingssysteem en schermresolutie van de gebruiker;</li>
        <li>Gebruikersinformatie: taal &amp; locatie van de gebruiker op basis van browsersettings en IP-adres. Deze locatie zal een ruwe schatting zijn omdat het IP-adres wordt verkort;</li>
        <li>Via welk platform of welke website de gebruiker op de website van Vanbreda belandt, hoe lang de gebruiker blijft en hoeveel pagina’s hij bezoekt, en op welke datum en tijdstip dit bezoek plaatsvindt.</li>
      </ul>
      <p>Verder maakt de website van Vanbreda gebruik van Google Tag Manager. Deze tool van Google Inc. zorgt ervoor dat er verschillende tags op de website geplaatst kunnen worden. Google Tag Manager maakt zelf geen gebruik van cookies, of verzamelt geen informatie van haar gebruikers.</p>

      <h2>10. Privacybeleid verzekeraars</h2>
      <p>Vanbreda werkt samen met verschillende verzekeraars. U kan het privacybeleid van uw verzekeraar terugvinden op hun website:</p>
      <ul>
        <li>AG Insurance: <a href='https://www.aginsurance.be/'>https://www.aginsurance.be/</a></li>
        <li>AGA (Allianz Global Assistance)&nbsp;: <a href='https://www.allianz-assistance.be/'>https://www.allianz-assistance.be/</a></li>
        <li>Allianz: <a href='https://allianz.be/'>https://allianz.be/</a></li>
        <li>Athora (voorheen Generali): <a href='https://www.athora.com/'>https://www.athora.com/</a></li>
        <li>AXA: <a href='https://www.axa.be/'>https://www.axa.be/</a></li>
        <li>Baloise: <a href='https://www.baloise.be/'>https://www.baloise.be/</a></li>
        <li>Belfius: <a href='https://www.belfius.be/'>https://www.belfius.be/</a></li>
        <li>Ethias: <a href='https://www.ethias.be/'>https://www.ethias.be/</a></li>
        <li>Fidea: <a href='https://www.fidea.be/'>https://www.fidea.be/</a></li>
        <li>Justitia: <a href='https://www.justitia.be/'>https://www.justitia.be/</a></li>
        <li>NN (voorheen Delta Lloyd): <a href='https://www.nn.be/'>https://www.nn.be/</a></li>
        <li>Vivium: <a href='https://www.vivium.be/'>https://www.vivium.be/</a></li>
      </ul>
      <p>Datum publicatie: 22/10/2019</p>
    </Container>
  )
}

export default Privacy
