import React, { useState, useContext } from 'react'
import {
  CardElement,
  injectStripe
} from 'react-stripe-elements'
import { ProspectContext } from './../contexts/ProspectContext'
import { Form, Button } from 'react-bootstrap'
import Loader from './Loader'
import { handleCardPayment } from '../utils/payment'

const CardForm = ({ handleError, handleLoading, handleSuccess, loading, stripe, elements }) => {
  const { state } = useContext(ProspectContext)
  const [complete, setComplete] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')

  const payload = {
    PaymentMethod: 'card',
    ProspectId: state.prospect.prospectId,
    SelectedPlan: state.selectedPlan.id
  }

  // Handle onChange Stripe Form validation
  const handleChange = (change) => {
    setComplete(change.complete)
    if (change.error) {
      setFormErrorMessage(change.error.message)
    } else {
      setFormErrorMessage('')
    }
  }

  // Handle Stripe Form submission
  const handleSubmit = async (evt) => {
    evt.preventDefault()
    if (!complete) return false
    if (stripe) {
      handleLoading(true)
      handleCardPayment(payload, stripe, elements.getElement('card')).then((certificateUrl) => {
        handleSuccess({
          completed: true,
          certificate: certificateUrl
        })
      }).catch(() => {
        handleError(true)
      }).finally(() => {
        handleLoading(false)
      })
    }
  }

  return (
    <>
      {loading && (
        <Loader loadingText='Betaling verwerken…' />
      )}
      <div className='CardForm' {...(loading ? { style: { display: 'none' } } : {})}>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Kaart details</Form.Label>
            <CardElement className='form-control' onChange={handleChange} {...createOptions()} />
            <Form.Control.Feedback type='invalid'>{formErrorMessage}</Form.Control.Feedback>
          </Form.Group>
          <Button type='submit' disabled={!complete}>Onderschrijven</Button>
        </Form>
      </div>
    </>
  )
}

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'The Sans, Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#c23d4b'
      }
    }
  }
}

export default injectStripe(CardForm)
