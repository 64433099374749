import React, { useContext } from 'react'
import errorImage from './../img/payment_error.svg'
import reloadImage from './../img/icon_reload.svg'
import { ProspectContext } from './../contexts/ProspectContext'
import { Button } from 'react-bootstrap'

const PaymentError = () => {
  const { state } = useContext(ProspectContext)

  const handleReload = () => {
    window.location.href = `/ui/prospect/${state.prospect.prospectId}/${state.selectedPlan.title}/subscribe`
  }

  return (
    <div className='PaymentError text-center'>
      <img className='mb-4' src={errorImage} alt='Payment error' />
      <h1 className='mb-2'>Er is iets fout gegaan</h1>
      <p className='lead mb-4'>Probeer het met een andere betaalmethode.</p>
      <Button onClick={handleReload}><img src={reloadImage} alt='Reload page' style={{ marginRight: '.5rem' }} />Opnieuw</Button>
      <p className='mt-4'>Bij aanhoudende problemen, contacteer <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a> onder vermelding van uw naam, uw contactgegevens, de naam van het faillissement en bijbehorend regsolnummer.</p>
    </div>
  )
}

export default PaymentError
