import React, { useContext } from 'react'
import { ProspectContext } from './../contexts/ProspectContext'
import { Button, Card, Jumbotron } from 'react-bootstrap'
import moment from 'moment'

import downloadImage from './../img/icon_download.svg'

const CaseRow = ({ item }) => {
  return (
    <Jumbotron>
      <Card.Body className='d-flex px-0 py-0'>
        <div>
          <Card.Title>Certificaat van {moment(item.createdAt).format('DD/MM/YYYY')}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Plan {item.plan}</Card.Subtitle>
        </div>
        <Button className='ml-auto px-4' href={item.certificateUrl}>
          <img src={downloadImage} alt='Download uw certificaat' />
        </Button>
      </Card.Body>
    </Jumbotron>
  )
}

const ListDownloads = () => {
  const { state } = useContext(ProspectContext)

  const cases = state.prospect.cases.map((caseItem, index) => <CaseRow key={index} item={caseItem} />)

  if (!state.prospect.cases) {
    return null
  }

  return (
    <div className='ListDownloads'>
      {cases}
    </div>

  )
}

export default ListDownloads