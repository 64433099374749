import React from 'react'
import { Container, Jumbotron } from 'react-bootstrap'

const Message = (props) => {
  return (
    <Container className='d-flex flex-column align-items-center'>
      <Jumbotron className='Message text-center'>
        {props.title && (
          <h1>{props.title}</h1>
        )}
      </Jumbotron>
    </Container>
  )
}

export default Message
