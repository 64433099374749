import React, { useContext } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { Badge, Button, Card, Col, Row } from 'react-bootstrap'
import { ProspectContext } from './../contexts/ProspectContext'

const Plans = () => {
  const { state, dispatch } = useContext(ProspectContext)
  const history = useHistory()

  // Handle plan selection
  const handleSelectPlan = (id) => {
    const plan = state.prospect.plans.find(plan => {
      return plan.id === id
    })
    // Save selected plan to context as state.selectedPlan
    dispatch({ type: 'updateSelectedPlan', payload: plan })

    // Redirect to subscribe page
    history.push(`/prospect/${state.prospect.prospectId}/${id}/subscribe`)
  }

  return (
    <div className='Plans'>
      {state.prospect.plans.length > 0 && (
        <div className='plans'>
          <Row className='mt-5'>
            {state.prospect.plans.map((plan) => (
              <Col className='mt-4 mt-lg-0' md={12} lg={4} key={plan.id}>
                <Card className='h-100'>
                  <Card.Header className='text-center'>
                    <h2><Badge pill variant='secondary'>{plan.title}</Badge></h2>
                  </Card.Header>
                  <Card.Body>
                    <ul className='list-unstyled'>
                      {plan.guarantees.map((guarantee, index) => (
                        <li key={index}>{guarantee}</li>
                      ))}
                    </ul>
                  </Card.Body>
                  <Card.Footer>
                    <Button onClick={() => handleSelectPlan(plan.id)} variant='primary'>Onderschrijven</Button>
                  </Card.Footer>
                </Card>
              </Col>
            )
            )}
          </Row>
          <p className='mt-4'>*Pro Deo faillissement = faillissement dat op het ogenblik van de opmaak van het eerste PV van verificatie een actief heeft van ≤ 5.000 EUR</p>
        </div>
      )}
    </div>
  )
}

export default withRouter(Plans)
