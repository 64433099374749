import React from 'react'
import logo from './../img/vrb.svg'
import { Link } from 'react-router-dom'
import { Container, Nav } from 'react-bootstrap'

const Footer = () => {
  const footerMenu = [
    {
      name: 'FSMA 13718 A-R',
      url: ''
    },
    {
      name: 'AssurMiFID gedragsregels',
      url: '/assurmifid'
    },
    {
      name: 'Privacy',
      url: '/privacy'
    },
    {
      name: 'Cookie policy',
      url: '/cookie-policy'
    }
  ]

  const footerMenuItem = footerMenu.map((item, index) => {
    return (
      <Nav.Item key={index}>
        <Nav.Link as={Link} {...(item.url) ? { to: item.url } : { to: '', disabled: true }}>{item.name}</Nav.Link>
      </Nav.Item>
    )
  })

  return (
    <footer>
      <div className='level-1'>
        <Container>
          <img
            alt=''
            src={logo}
            width='103'
            height='46'
            className='logo d-inline-block align-top'
            style={{ marginRight: 20 }}
          />
          <Nav>
            {footerMenuItem}
          </Nav>
        </Container>
      </div>
      <div className='level-2'>
        <Container>
          <p>&copy; {new Date().getFullYear()} Vanbreda Risk &amp; Benefits</p>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
