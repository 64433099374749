import React from 'react'
import { Container } from 'react-bootstrap'

const CookiePolicy = () => {
  return (
    <Container className='legal-page'>
      <h1>Cookie policy</h1>
      <p className='h4'>Wij gebruiken cookies op onze websites, platformen of websites van derden waarop wij deze pagina’s tonen. Hieronder vindt u meer informatie over cookies en over hoe u deze kunt beheren. Door onze website en/of diensten te gebruiken, aanvaardt u het gebruik van cookies zoals beschreven in deze Cookie Policy. Als u het gebruik van cookies niet aanvaardt, kunt u ze uitschakelen door de instructies in deze Cookie Policy te volgen, bijvoorbeeld door uw browserinstellingen te veranderen zodat cookies van deze website niet op uw toestel opgeslagen kunnen worden.</p>
      <h2>Welke termen gebruiken we in deze Cookie Policy?</h2>
      <ul>
        <li>We gebruiken de term “gebruiker” om te verwijzen naar de gebruikers van onze website, online pagina’s en diensten.</li>
        <li>We gebruiken de termen “u” en “uw” om te verwijzen naar de gebruiker die deze Cookie Policy leest.</li>
        <li>We gebruiken de termen “wij”, “ons”, “onze” en “Vanbreda” om te verwijzen naar Vanbreda Risk &amp; Benefits NV en naar alle verbonden ondernemingen wereldwijd. De beheerder van de data is Vanbreda Risk &amp; Benefits NV, met hoofdzetel in de Plantin en Moretuslei 297, 2140 Antwerpen, België en met ondernemingsnummer: 0404.055.676.</li>
      </ul>

      <h2>Wat zijn cookies?</h2>
      <p>Cookies zijn tekstbestanden met kleine hoeveelheden informatie die op uw toestel gedownload worden wanneer u een website bezoekt. Bij elk volgende bezoek worden cookies vervolgens teruggestuurd naar de oorspronkelijke website of naar een andere website die dat cookie herkent. Cookies zijn nuttig omdat ze een website toelaten het toestel van de gebruiker te herkennen.</p>
      <p>Cookies vervullen verschillende taken. Ze zorgen er bijvoorbeeld voor dat u efficiënt tussen verschillende pagina’s kunt navigeren, ze onthouden uw voorkeuren en, meer algemeen, verbeteren ze uw gebruikservaring. Ze dragen er ook toe bij dat dat de reclame die u ziet op het internet beter aansluit bij uw persoon en interesses.</p>
      <p>Sommige cookies bevatten persoonlijke informatie – bijvoorbeeld, wanneer u bij het inloggen klikt op “onthoud mij” zal een cookie uw gebruikersnaam opslaan. De meeste cookies zullen geen gegevens opslaan die u identificeren, maar zullen in plaats daarvan meer algemene informatie verzamelen, zoals hoe gebruikers op de website terechtkomen en het gebruik ervan of zijn algemene locatie.</p>
      <p>Er zijn hoofdzakelijk vier soorten cookies:</p>
      <ul>
        <li><strong>Sessiecookies</strong> die enkel voor de duur van de sessie blijven. Zij worden gewist wanneer de gebruiker de browser sluit.</li>
        <li><strong>Permanente cookies</strong> blijven ook bewaard nadat de sessie is gesloten. Zij worden opgeslagen op de harde schijf van de gebruiker totdat ze vervallen (permanente cookies hebben een vervaldatum) of totdat de gebruiker de cookie verwijdert. Permanente cookies kunnen gebruikt worden om identificerende informatie over de gebruiker te verzamelen, zoals zijn surfgedrag of voorkeur voor een welbepaalde website.</li>
        <li><strong>First-party cookies</strong> behoren tot de site die u bezoekt.</li>
        <li><strong>Third-party cookies</strong> worden opgehaald van een site waarvan de inhoud indirect wordt binnengehaald op een andere website, bijvoorbeeld door een afbeelding of een banner.</li>
      </ul>
      <p>De meeste browsers ondersteunen cookies (Internet Explorer, Mozilla Firefox, Google Chrome, Safari, etc.). U kunt echter uw browser ook instellen om cookies te weigeren of u kunt uw cookies zelf verwijderen wanneer u dat wenst.</p>

      <h2>Welke cookies gebruiken wij?</h2>
      <p>Globaal gezien vervullen onze cookies tot vier verschillende functies:</p>
      <p>
        <strong>1. Essentiële cookies</strong><br />
        Sommige cookies zijn essentieel voor de werking van onze website. Zij stellen ons bijvoorbeeld in staat om de gebruiker te identificeren en zorgen dat de gebruiker toegang heeft tot de registratie- of loginpagina’s. Die cookies verzamelen geen gegevens over u die voor marketingdoeleinden kunnen worden gebruikt of die onthouden waar u geweest bent op het internet. Deze categorie mag niet uitgeschakeld worden. Als een gebruiker ervoor kiest deze cookies toch uit te schakelen, zal hij geen toegang hebben tot alle inhoud die de betreffende pagina’s aanbieden.
      </p>
      <p>
        <strong>2. Performantie- en analysecookies</strong><br />
        Deze cookies verzamelen informatie over hoe bezoekers een website gebruiken, bijvoorbeeld welke pagina’s bezoekers het meest bezoeken en of ze foutmeldingen krijgen. Deze cookies verzamelen geen gegevens die u identificeren. De informatie verzameld door deze cookies is anoniem en wordt enkel gebruikt om de werking van de website te verbeteren.
      </p>
      <p>
        <strong>3. Functionaliteitscookies</strong><br />
        We kunnen gebruik maken van functionaliteitscookies om ons in staat te stellen uw voorkeuren te onthouden. Deze cookies zorgen er bijvoorbeeld voor dat u niet telkens uw gebruikersnaam moet ingeven bij elk nieuw bezoek aan de site, en ze onthouden uw instelbare voorkeuren, zoals welke regionale editie van de website u wilt zien wanneer u inlogt. We kunnen functionaliteitscookies ook gebruiken om u doorgedreven diensten aan te bieden, zoals u toestaan een video online te bekijken of te reageren op een blog.
      </p>
      <p>
        <strong>4. Deel-knoppen</strong><br />
        Onze websites kunnen deel- of “share”-knoppen bevatten om gebruikers eenvoudig in staat te stellen artikels te delen met hun contacten via websites van derden zoals Facebook, Twitter en LinkedIn. Deze sites kunnen sessiecookies en permanente cookies gebruiken, aangebracht door de deel-knoppen, om hun diensten te verbeteren (waaronder het begrijpen van de interactie tussen gebruikers, of om gezamenlijk gebruik en web traffic routing op te volgen). Meer informatie vindt u in de privacy policy van <a href='https://nl-be.facebook.com/privacy/explanation' rel='noopener noreferrer' target='_blank'>Facebook</a>, <a href='https://twitter.com/privacy' rel='noopener noreferrer' target='_blank'>Twitter</a> en <a href='https://www.linkedin.com/legal/privacy-policy' rel='noopener noreferrer' target='_blank'>LinkedIn</a>.
      </p>

      <h2>Hoe kan ik cookies beheren of verwijderen?</h2>
      <p>U kunt cookies toestaan, uitschakelen of verwijderen via uw browser. Daarvoor dient u de instructies van uw browser te volgen (meestal te vinden in de “Help”, “Tools” of “Edit”). Een cookie of cookiecategorie uitschakelen, verwijdert de cookie niet uit uw browser, dat moet u zelf doen vanuit uw browser. Gelieve ermee rekening te houden dat sommige onderdelen van onze website niet naar behoren kunnen werken indien u cookies uitschakelt.</p>
    </Container>
  )
}

export default CookiePolicy
