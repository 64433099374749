import { preparePayment, createCase } from './../utils/apiCall'

export const handleCardPayment = async (payload, stripe, cardElement) => {
  const { resource } = await preparePayment(payload)
  if (resource) {
    const { id } = await confirmPayment(stripe, resource, cardElement)
    if (id) {
      payload.PaymentSource = id
      const { status, certificateUrl } = await createCase(payload)
      if (status === 'subscribed') {
        return certificateUrl
      } else {
        throw new Error('Cannot confirm payment')
      }
    }
  } else {
    throw new Error('No resource number')
  }
}

export const prepareBancontactPayment = async (payload) => {
  const { resource } = await preparePayment(payload)
  if (resource) {
    window.location.href = resource
  } else {
    throw new Error('No resource number')
  }
}

export const proceedBancontactPayment = async (payload) => {
  const { status, certificateUrl } = await createCase(payload)
  if (status === 'subscribed') {
    return certificateUrl
  } else {
    throw new Error('Cannot confirm payment')
  }
}

const confirmPayment = async (stripe, clientSecret, cardElement) => {
  const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
    payment_method: { card: cardElement }
  })
  if (error) {
    throw new Error('Invalid confirmation')
  } else if (paymentIntent) {
    return paymentIntent
  }
}
