import React from 'react'
import { Container } from 'react-bootstrap'

const Loader = (props) => {
  let loadingText = 'Laden...'
  if (props.loadingText) {
    loadingText = props.loadingText
  }
  
  return (
    <Container className='d-flex flex-column align-items-center'>
      <div className='loader'>
        <div className='loader-inner d-flex justify-content-center align-items-center'>
          <svg width='28' height='7' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3.5 0a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm10 0a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm11 0a3.5 3.5 0 110 7 3.5 3.5 0 010-7z' fill='#FFF' />
          </svg>
        </div>
        <div className='loader-expander' />
      </div>
      <h1 className='mt-4'>{loadingText}</h1>
    </Container>
  )
}

export default Loader
