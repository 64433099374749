import axios from 'axios'

export const findProspect = (id) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/FindProspect`, {
    ProspectId: id,
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json'
    }
  })
    .then(response => response.data)
    .catch(error => error)
}

export const preparePayment = (payload) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/PreparePayment`, {
    ...payload,
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json'
    }
  })
    .then(response => response.data)
    .catch(error => error)
}

export const createCase = (payload) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/CreateCase`, {
    ...payload,
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json'
    }
  })
    .then(response => response.data)
    .catch(error => error)
}

export const createProspect = (payload) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/CreateProspect?source=diplad`, payload, {
    headers: {
      Accept: 'application/json',
      'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
    .then(response => response.data)
    .catch(error => {
      // this catch clause just swallows the error....
      console.log('ERROR', error)
      return error
    })
}

export const createReport = (payload) => {
  const axiosInstance = axios.create({
    validateStatus: (status) => status
  })

  return axiosInstance.post(`${process.env.REACT_APP_API_URL}/Createreport`, {
    ...payload,
    headers: {
      Accept: 'application/json',
      'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}
