import React, { useState } from 'react'
import ReportSearch from '../components/ReportSearch'
import Loader from '../components/Loader'
import { Container, Jumbotron } from 'react-bootstrap'

const Report = () => {
  const [loading, setLoading] = useState(false)

  return (
    <Container>
      <Jumbotron>
        {loading && (
          <Loader />
        )}
        <ReportSearch loading={loading} setLoading={setLoading} />
      </Jumbotron>
    </Container>
  )
}

export default Report