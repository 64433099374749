import React from 'react'
import { Container } from 'react-bootstrap'

const AssurMifid = () => {
  return (
    <Container className='legal-page'>
      <h1>AssurMiFID gedragsregels</h1>
      <h2>1. Vanbreda</h2>
      <p>De Vanbreda-groep is een geheel van vennootschappen die zich bezighouden met verzekeringsmakelaars- en diverse verzekeringsactiviteiten, vermogensbeheer, risk management en claimshandling en/of activiteiten die in het verlengde hiervan liggen. Elke onderneming uit de groep is door de FSMA erkend als onafhankelijke verzekeringsmakelaar.</p>
      <p>De Vanbreda-groep in België bestaat uit:</p>
      <ul>
        <li>Vanbreda Risk &amp; Benefits – FSMA nr. 13718 A-R (BE 0404.055.676)</li>
        <li>Vanbreda Ausloos – FSMA nr. 47833 A (BE 0474.182.421)</li>
        <li>Vanbreda Cornelis – FSMA nr. 12565 A (BE 0425.054.196)</li>
        <li>Vanbreda Credinco – FSMA nr. 042518 A (BE 0462.175.702)</li>
        <li>Vanbreda Geerts – FSMA nr. 044401 A (BE 0466.513.679)</li>
        <li>Vanbreda Huysmans – FSMA nr. 067446 A (BE 0478.505.552)</li>
        <li>Vanbreda Missinne – FSMA nr. 013584 A (BE 0426.071.906)</li>
      </ul>
      <p>In Nederland wordt de Vanbreda-groep vertegenwoordigd door <a href='http://www.vanbredanl.com/' rel='noopener noreferrer' target='_blank'>Vanbreda Risk &amp; Benefits Nederland</a> en in Luxemburg door <a href='http://www.vanbredalang.lu' rel='noopener noreferrer' target='_blank'>Vanbreda &amp; Lang</a>.</p>
      <p>De Vanbreda-groep heeft nauwe banden met de verzekeringsmaatschappij <a href='https://www.vanbreda.be/nl/over-vanbreda/justitia/' rel='noopener noreferrer' target='_blank'>Justitia</a>. Justitia is gespecialiseerd in het verzekeren van Employee Benefitsplannen die door de Vanbreda-groep op maat van de onderneming-verzekeringnemer worden uitgewerkt.</p>

      <h2>2. Werkwijze</h2>
      <p><strong>2.1 Verzekeringsbemiddeling</strong></p>
      <p>Als verzekeringsmakelaar zijn wij gespecialiseerd in het adviseren over en het aanbieden en beheren van verzekeringsovereenkomsten. Hiervoor verrichten wij het voorbereidend werk tot het sluiten van de verzekeringsovereenkomsten en assisteren wij bij hun beheer en uitvoering, met inbegrip van schadeaangiftes.<br /> Op basis van de informatie die u ons geeft, werken wij een optimale oplossing voor u uit. Daarom is het belangrijk dat u ons op voorhand nauwkeurig informeert over alle bekende omstandigheden en wensen die redelijkerwijze beschouwd moeten worden als gegevens die een invloed uitoefenen op het voorstel dat wij u zullen doen. Wij adviseren altijd op basis van een onpartijdige analyse. Onze analyse is m.a.w. gebaseerd op een toereikend aantal op de verzekeringsmarkt verkrijgbare verzekeringsoplossingen die beantwoorden aan uw verlangens en behoeften. Door de verzekeringsovereenkomst te sluiten erkent u dat ons advies rekening houdt met uw verlangens en behoeften en dat u gewezen werd op de draagwijdte en beperkingen van het verzekeringsproduct dat u hebt gekozen.</p>
      <p><strong>2.2 Verzekeringstakken</strong></p>
      <p>De meeste ondernemingen binnen de Vanbreda-groep kunnen als verzekeringsmakelaar optreden voor alle verzekeringstakken die hierna opgesomd worden. Als één van de ondernemingen voor een bepaalde tak geen erkenning heeft, staat dit bij de tak vermeld. Omwille van haar nichespecialisatie heeft Vanbreda Credinco enkel een erkenning voor de takken 14, 15 en 21.</p>
      <p><strong>2.2.1 Groep van activiteiten “niet-leven”</strong></p>
      <p>
        1. <strong>Ongevallen</strong><br />
        1.a. Ongevallen behalve arbeidsongevallen: forfaitaire uitkeringen; schadeloosstellingen; combinaties daarvan; vervoerde personen.<br />
        1.b. Arbeidsongevallen<br />
        2. <strong>Ziekte</strong>: forfaitaire uitkeringen; schadeloosstellingen; combinaties daarvan; afhankelijkheidsverzekering.<br />
        3.<strong> Voertuigcasco</strong> met uitzondering van rollend spoorwegmaterieel: alle schade toegebracht aan motorrijtuigen en voertuigen zonder motor.<br />
        4. <strong>Casco rollend spoorwegmateriaal</strong>: alle schade die eraan toegebracht wordt.<br />
        5. <strong>Luchtvaartuigcasco</strong>: alle schade die eraan toegebracht wordt.<br />
        6. <strong>Casco zee- en binnenschepen</strong>: alle schade toegebracht aan binnenschepen; schepen voor de vaart op meren; zeeschepen.<br />
        7. <strong>Vervoerde goederen</strong> met inbegrip van koopwaren, bagage en alle andere goederen: alle schade toegebracht aan vervoerde goederen of bagage, ongeacht de aard van het vervoermiddel.<br />
        8. <strong>Brand en natuurevenementen</strong>: alle schade toegebracht aan goederen (met uitzondering van de goederen welke onder de sub 3, 4, 5, 6 en 7 bedoelde takken zijn begrepen), wanneer die zijn veroorzaakt door brand; ontploffing; storm; natuurevenementen, met uitzondering van hagel en vorst; kernenergie en aardverzakking.<br />
        9. <strong>Andere schade aan goederen</strong>: alle schade toegebracht aan goederen (met uitzondering van de goederen welke onder sub 3, 4, 5, 6 en 7 bedoelde takken zijn begrepen), wanneer die schade veroorzaakt is door hagel of vorst, alsmede door alle overige niet reeds sub 8 begrepen evenementen, zoals diefstal.<br />
        10. <strong>B.A. motorrijtuigen</strong>: Elke aansprakelijkheid die het gevolg is van het gebruik van motorrijtuigen.<br />
        10a: verplichte verzekering der burgerrechtelijke aansprakelijkheid inzake motorrijtuigen<br />
        10b: verzekering aansprakelijkheid van de vervoerder<br />
        11. <strong>B.A. luchtvaartuigen</strong>: Elke aansprakelijkheid die het gevolg is van het gebruik van luchtvaartuigen (met inbegrip van de aansprakelijkheid van de vervoerder).<br />
        12. <strong>B.A. zee- en binnenschepen</strong>: Elke aansprakelijkheid die het gevolg is van het gebruik van zee- en binnenschepen (met inbegrip van de aansprakelijkheid van de vervoerder).<br />
        13.<strong> Algemene B.A.</strong>: alle overige niet reeds onder de nrs. 10, 11 en 12 vermelde vormen van aansprakelijkheid.<br />
        14.<strong> Krediet</strong>: algemene insolventie; exportkrediet; verkoop op afbetaling; hypothecair krediet; landbouwkrediet.<br />
        15. <strong>Borgtocht</strong>: directe borgtocht; indirecte borgtocht.<br />
        16. <strong>Diverse geldelijke verliezen</strong>: risico van gebrek aan werk; (algemeen) tekort aan ontvangsten; slecht weer; winstderving; doorlopende hoge algemene kosten; onvoorziene bedrijfsuitgaven; verlies van verkoopwaarde; huur- of inkomstenderving; andere indirecte bedrijfsverliezen dan bovengenoemde; niet met een bedrijf samenhangende geldelijke verliezen; overige geldelijke verliezen.<br />
        17. <strong>Rechtsbijstand</strong>.<br />
        18. <strong>Hulpverlening</strong>: hulpverlening aan in moeilijkheden verkerende personen die op reis zijn of zich buiten hun woonplaats of vaste verblijfplaats bevinden; hulpverlening onder andere omstandigheden.
      </p>
      <p>
        <strong>2.2.2 Groep van activiteiten “leven”</strong>
      </p>
      <p>
        21. Levensverzekeringen, niet verbonden met beleggingsfondsen, met uitzondering van bruidsschats- en geboorteverzekeringen.<br />
        22. Bruidsschats- en geboorteverzekeringen, niet verbonden met beleggingsfondsen.<br />
        23. Levens-, bruidsschats- en geboorteverzekeringen in verband met beleggingsfondsen.<br />
        24. De in Ierland en het Verenigd Koninkrijk bestaande verzekering genaamd “permanent health insurance” (niet opzegbare ziekteverzekering van lange duur).<br />
        25. Tontineverrichtingen.<br />
        26. Kapitalisatieverrichtingen.<br />
        27. Beheer van collectieve pensioenfondsen.<br />
        28. De verrichtingen zoals bedoeld in boek IV, titel 4, hoofdstuk I van de Franse “Code des assurances”.<br />
        29. De in de wetgeving op de sociale verzekering omschreven en bedoelde verrichtingen in verband met de duur van het leven van de mens, voor zover deze in overeenstemming zijn met de wetgeving van een Europese Lidstaat, en door verzekeringsondernemingen voor eigen risico worden verricht of beheerd.
      </p>
      <p>Meer informatie over onze diensten in het algemeen en de verschillende soorten polissen waarvoor wij bemiddelen, vindt u in&nbsp;<a href='https://www.vanbreda.be/nl/oplossingen-voor-uw-risicos/' rel='noopener noreferrer' target='_blank'>‘Oplossingen voor uw risico’s’</a>&nbsp;en ‘<a href='https://www.vanbreda.be/nl/service-op-uw-maat/' rel='noopener noreferrer' target='_blank'>Service op uw maat</a>‘. Die informatie heeft enkel een informatieve waarde en is geen samenvatting van alle geldende dekkingsvoorwaarden. Als verzekeringsmakelaar werken wij met verschillende maatschappijen samen. Elke maatschappij heeft echter haar eigen algemene en bijzondere polisvoorwaarden. Om te beslissen welke verzekeringsdekkingen best op uw behoeftes aansluiten , is een volledige studie nodig. Indien nodig onderhandelen wij met de verzekeraar polisvoorwaarden op maat.</p>
      <p>
        <strong>2.3 Gedragsregels</strong>
      </p>
      <p>
        Wij respecteren de gedragsregels, ongeacht of ze voortvloeien uit de wet of uit de deontologie voor de tussenpersonen.<br />
        Dit houdt eveneens in dat wij, als lid van de <a href='http://www.bvvm.be' rel='noopener noreferrer' target='_blank'>BVVM</a>, ook voor ons belangenconflictenbeleid en ons vergoedingsbeleid de regels en principes volgen die op sectoraal niveau door de beroepsfederatie werden aangenomen.
      </p>
      <p><strong>2.4 <a href='https://www.vanbreda.be/nl/privacy/' rel='noopener noreferrer' target='_blank'>Privacybeleid</a></strong></p>
      <p>Wij eerbiedigen uw persoonlijke levenssfeer in overeenstemming met de geldende wetgeving.</p>
      <p>U bent niet verplicht om te antwoorden op de door ons gestelde vragen, maar het niet antwoorden op die vragen kan echter tot gevolg hebben, naargelang het geval, dat het voor ons onmogelijk is of dat wij weigeren om een (pre)contractuele relatie aan te gaan, om een dergelijke relatie voort te zetten of om een verrichting uit te voeren gevraagd door u of door een derde in uw voordeel.</p>
      <h2>3. Gebruik van onze website</h2>
      <p>Door <a href='https://www.vanbreda.be/nl/' rel='noopener noreferrer' target='_blank'>onze website</a> te gebruiken, verklaart u dat u kennis heeft genomen van de informatie hieronder.</p>
      <p>Door via de website persoonlijke gegevens mee te delen, geeft u Vanbreda de toelating om die te verwerken zoals bepaald door ons Privacybeleid. Omdat het internet geen volledige veiligheid biedt, kan de eerbiediging van de persoonlijke levenssfeer enkel gegarandeerd worden indien de persoonsgegevens meegedeeld worden met communicatiemogelijkheden waarvan u uitdrukkelijk vaststelt dat zij beveiligd zijn.</p>
      <p><strong>3.1.1 Geen garantie</strong></p>
      <p>De teksten op deze website zijn zorgvuldig opgesteld, maar blijven noodgedwongen veralgemeningen. Ze mogen niet beschouwd worden als advies of een overzicht van verworven dekkingen. Dat laatste blijkt enkel uit de gesloten verzekeringspolis. Wij hebben het recht om de inhoud van de website aan te passen, te wijzigen of te verwijderen.</p>
      <p>Geen enkele link naar websites van derden staat onder ons toezicht. Wij hebben geen zeggenschap over hun inhoud of andere kenmerken van die websites. Wij verstrekken die links uitsluitend voor de gebruiksvriendelijkheid en het feit dat wij die links opnemen, impliceert niet dat wij de informatie op die websites goedkeuren.</p>
      <p>De Vanbreda-groep aanvaardt geen enkele aansprakelijkheid voor onnauwkeurigheden of gebreken in de informatie verstrekt via onze website. Indien u op basis van deze informatie beslissingen neemt, is dit uw exclusieve verantwoordelijkheid. U kunt ons altijd contacteren voor een advies op maat.</p>
      <p><strong>3.1.2 <a href='https://www.vanbreda.be/nl/cookie-policy/' rel='noopener noreferrer' target='_blank'>Cookies</a></strong></p>
      <p>Cookies zijn kleine informatiebestanden die een website tijdens uw bezoek op uw harde schijf plaatst.</p>
      <p>Op de website van de Vanbreda-groep wordt gebruik gemaakt van ‘session cookies’, die tijdelijk actief blijven. Session cookies worden weer van uw computer verwijderd zodra u uw webbrowser afsluit. Bovendien bevatten onze session cookies geen persoonlijke gegevens.</p>
      <p>De cookies die worden aangemaakt door onze website identificeren uw specifieke wensen en voorkeuren en laten toe dat de diensten daarop worden afgestemd.</p>
      <p>De meeste Internetbrowsers (Internetnavigatoren) zijn automatisch ingesteld om cookies te aanvaarden. Door de instelling van uw Internetbrowser te wijzigen, kunt u er echter voor zorgen dat u verwittigd wordt telkens er een cookie wordt gestuurd of beletten dat cookies worden gecreëerd of bewaard op uw harde schijf. De Vanbreda-groep kan echter de toegang tot haar website niet garanderen indien u de aanmaak van ‘session cookies’ weigert.</p>
      <p><strong>3.1.3 Vermelding van auteursrecht</strong></p>
      <p>Alle teksten, tekeningen, foto’s, films, benamingen, handels- en domeinnamen, merken, logo’s… vermeld op deze site zijn beschermd door intellectuele rechten en behoren de Vanbreda-groep of derden toe.</p>
      <p>Het is verboden zonder ons voorafgaand schriftelijk akkoord de op en via deze website aangeboden informatie op te slaan (anders dan noodzakelijk om de website te kunnen bekijken), te reproduceren, te wijzigen, openbaar te maken, te distribueren of te verzenden, te verkopen of anderszins over te dragen of enige rechten hierop te verlenen aan derden.</p>
      <p>Enkel onder volgende voorwaarden is het toegestaan de informatie op deze website te gebruiken:</p>
      <p>(1) onderstaande vermelding van auteursrecht wordt voorzien op alle documenten en kopieën die worden gereproduceerd of voortgebracht vanaf deze website (“de documenten”),</p>
      <p>(2) de documenten uitsluitend voor informatieve en persoonlijke of niet-commerciële doeleinden worden gebruikt en de documenten niet op andere netwerken worden geplaatst</p>
      <p>(3) er geen wijzigingen en aanpassingen aan de documenten worden aangebracht.</p>
      <p>Het gebruik voor andere doeleinden is bij wet uitdrukkelijk verboden en wij kunnen schendingen van of inbreuken op onze rechten vervolgen.</p>
      <p><em>Copyright VANBREDA RISK &amp; BENEFITS NV</em><br />
        <em>Alle rechten voorbehouden.</em>
      </p>
    </Container>
  )
}

export default AssurMifid
