import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { findProspect } from '../utils/apiCall'
import { ProspectContext } from '../contexts/ProspectContext'

import Loader from './Loader'
import Message from './Message'
import DownloadMessage from './DownloadMessage'
import { isEmpty } from 'lodash'

const ProspectWrapper = (props) => {
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState(null)
  const { state, dispatch } = useContext(ProspectContext)
  const { prospectId } = useParams()
  const { planId } = useParams()

  // First Step: findProspect
  useEffect(() => {
    findProspect(prospectId)
      .then((data) => {
        dispatch({ type: 'updateProspect', payload: data })
        setStatus(data.status)
      })
      .catch(error => error)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // If there is a planId in URL, save selected plan to state
  useEffect(() => {
    if (!isEmpty(state.prospect.plans) && planId) {
      const plan = state.prospect.plans.find(plan => {
        return plan.id === planId
      })

      dispatch({ type: 'updateSelectedPlan', payload: plan })
    }
  }, [state.prospect, planId, dispatch])

  // Display Loader while fetching prospect
  if (loading) {
    return (
      <Loader loadingText='Case laden...' />
    )
  }

  // Check status
  if (status === 'open') {
    // Display Case
    return (
      { ...props.children }
    )
  } else if (status === 'subscribed') {
    return (
      <DownloadMessage certificate={state.prospect.cases[0].certificateUrl} />
    )
  } else {
    return (
      <Message title='Geef een geldige case' />
    )
  }
}

export default ProspectWrapper
