import React, { useState, useEffect, useContext } from 'react'
import {
  StripeProvider,
  Elements
} from 'react-stripe-elements'
import bancontact from './../img/bancontact.svg'
import creditCard from './../img/credit_card.svg'
import { Row, Jumbotron, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import BancontactForm from './../components/BancontactForm'
import CardForm from './../components/CardForm'
import PaymentError from './../components/PaymentError'
import DownloadMessage from './DownloadMessage'
import { ProspectContext } from './../contexts/ProspectContext'

const StripePayment = ({ stripe, processingBancontactPayment }) => {
  const { state } = useContext(ProspectContext)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState({
    completed: false,
    certificate: null
  })
  const [mode, setMode] = useState('card')
  const [loading, setLoading] = useState(false)

  // Handle Loading Message
  const handleLoading = (newLoadingState) => {
    setLoading(newLoadingState)
  }

  // Handle Error
  const handleError = (newErrorState) => {
    setError(newErrorState)
  }

  // Handle Error
  const handleSuccess = (sucess) => {
    setSuccess(sucess)
  }

  // Handle Payment Method Switch
  const handleModeChange = (val) => {
    if (mode !== val) {
      setMode(val)
    }
  }

  // Handle Payment Method Switch
  let input = <CardForm handleLoading={handleLoading} handleError={handleError} handleSuccess={handleSuccess} loading={loading} />
  if (mode === 'bancontact') {
    input = <BancontactForm processingBancontactPayment={processingBancontactPayment} handleLoading={handleLoading} handleError={handleError} handleSuccess={handleSuccess} loading={loading} />
  }

  // Switch to bancontact if processing bancontact payment
  useEffect(() => {
    if (processingBancontactPayment.status) {
      setMode('bancontact')
    }
  }, [processingBancontactPayment, state])

  return (
    <>
      <ToggleButtonGroup className='mb-4' type='radio' name='paymentMethod' size='lg' value={mode} onChange={handleModeChange}>
        <ToggleButton variant='light' value='card'>
          <img src={creditCard} height='16' className='mr-3' alt='Credit card' />
          Kredietkaart
        </ToggleButton>
        <ToggleButton variant='light' value='bancontact'>
          <img src={bancontact} height='16' className='mr-3' alt='Bancontact' />
          Bancontact
        </ToggleButton>
      </ToggleButtonGroup>
      {success.completed ? (
        <Row>
          <DownloadMessage title='U bent verzekerd voor dit mandaat' certificate={success.certificate} />
        </Row>
      ) : (
        <>
          <Jumbotron>
            {error && (
              <PaymentError />
            )}
            {!error && (
              <StripeProvider stripe={stripe}>
                <Elements locale='nl'>
                  {input}
                </Elements>
              </StripeProvider>
            )}
          </Jumbotron>
        </>
      )}
    </>
  )
}

export default StripePayment
