import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import StripePayment from '../components/StripePayment'
import { ProspectContext } from './../contexts/ProspectContext'
import queryString from 'qs'
import backImage from './../img/go_back.svg'

const Subscribe = () => {
  const { state } = useContext(ProspectContext)
  const [stripe, setStripe] = useState(null)
  const [processingBancontactPayment, setProcessingBancontactPayment] = useState({
    status: false,
    source: null
  })
  const location = useLocation()
  const history = useHistory()

  // Check if bancontact payment is processing (=handle bancontact redirection)
  useEffect(() => {
    if (location.search) {
      const query = queryString.parse(location.search) || {}
      const paymentIntent = query.payment_intent || query['?payment_intent']

      if (paymentIntent) {
        setProcessingBancontactPayment({
          status: true,
          source: paymentIntent
        })
        history.push(`/prospect/${state.prospect.prospectId}/${state.selectedPlan.title}/subscribe`)
      }
    }
  }, [location.search, state, history])

  // Load Stripe if not loaded
  useEffect(() => {
    let isLoaded = true
    if (window.Stripe) {
      if (isLoaded) {
        setStripe(window.Stripe(process.env.REACT_APP_STRIPE_API_KEY))
      }
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        setStripe(window.Stripe(process.env.REACT_APP_STRIPE_API_KEY))
      })
    }
    return () => { isLoaded = false }
  }, [])

  // Display case details and payment form
  return (
    <div className='Subscribe'>
      <Helmet>
        <title>BA beroep mandaten Ond. Rb.</title>
      </Helmet>
      <Container>
        <section>
          <h1>
            <a href={`/ui/prospect/${state.prospect.prospectId}`} style={{ marginRight: '1rem' }}><img src={backImage} alt='Ga Terug' /></a>
            Onderschrijven Formule {state.selectedPlan.title} <span className='highlighted small'>voor {state.selectedPlan.price}&euro;</span>
          </h1>
          <p className='h4 mt-5 mb-4'>Uw details :</p>
          <Row>
            <Col className='mb-3 mb-lg-0' md={6} lg={3}>
              <p className='h5'>Gerechtsmandataris :</p>
              <p>{state.prospect.model.firstName} {state.prospect.model.lastName}</p>
            </Col>
            <Col className='mb-3 mb-lg-0' md={6} lg={3}>
              <p className='h5'>E-mail :</p>
              <p>{state.prospect.model.email}</p>
            </Col>
            <Col className='mb-3 mb-lg-0' md={6} lg={3}>
              <p className='h5'>Rechtbank en afdeling / Hof :</p>
              <p>{state.prospect.model.courtAndDepartment}</p>
            </Col>
            <Col className='mb-3 mb-lg-0' md={6} lg={3}>
              <p className='h5'>Mandaatnummer :</p>
              <p>{state.prospect.model.bankruptcyNumber}</p>
            </Col>
          </Row>
        </section>
        <section className='mt-3 mt-lg-5'>
          <Row>
            <Col md={12} lg={{ span: 8 }}>
              <p className='h4 mb-3'>Betaling :</p>
              <StripePayment stripe={stripe} processingBancontactPayment={processingBancontactPayment} />
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  )
}

export default Subscribe
