import React, { createContext, useReducer } from 'react'

const ProspectContext = createContext()

const initialState = {
  prospect: {},
  selectedPlan: {}
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateSelectedPlan':
      return { ...state, selectedPlan: { ...action.payload } }
    case 'updateProspect':
      return { ...state, prospect: { ...action.payload } }
    case 'reset':
      return initialState
    default:
      return { ...state }
  }
}

const ProspectContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }

  return (
    <ProspectContext.Provider value={value}>
      {props.children}
    </ProspectContext.Provider>
  )
}

const ProspectContextConsumer = ProspectContext.Consumer

export { ProspectContext, ProspectContextProvider, ProspectContextConsumer }