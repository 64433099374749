import React, { useState } from 'react'
import MaskedFormControl from 'react-bootstrap-maskedinput'
import { Helmet } from 'react-helmet'
import { Button, Col, Container, Form } from 'react-bootstrap'
import { Formik, getIn } from 'formik'
import * as yup from 'yup'
import { createProspect } from './../utils/apiCall'
import Loader from './../components/Loader'
import queryString from 'qs'
import moment from 'moment'

const Diplad = () => {
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    CourtAndDepartment: '',
    BankruptcyName: '',
    BankruptcyAddress: {
      StreetNumber: '',
      StreetName: '',
      PostalCode: '',
      City: '',
      Country: ''
    },
    VerdictDate: '',
    FirstName: '',
    LastName: '',
    Email: '',
    RoleNumber: '',
    Balie: ''
  })

  const dateRegex = /(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d{2}/g

  const validationSchema = yup.object().shape({
    CourtAndDepartment: yup.string()
      .required('Rechtbank en afdeling / Hof veld is verplicht.'),
    BankruptcyName: yup.string()
      .required('Naam en vennootschapsvorm van de onderneming/ natuurlijke persoon veld is verplicht.'),
    BankruptcyAddress: yup.object().shape({
      StreetNumber: yup.string()
        .required('Huisnr en busnr veld is verplicht.'),
      StreetName: yup.string()
        .required('Straat veld is verplicht.'),
      PostalCode: yup.mixed()
        .required('Postcode veld is verplicht.'),
      City: yup.string()
        .required('Gemeente veld is verplicht.'),
      Country: yup.string()
        .required('Land veld is verplicht')
    }),
    VerdictDate: yup.string()
      .required('Datum uitspraak veld is verplicht')
      .matches(dateRegex, 'Geef een geldige datum op'),
    FirstName: yup.string()
      .required('Voornaam veld is verplicht.'),
    LastName: yup.string()
      .required('Achternaam veld is verplicht.'),
    Email: yup.string()
      .email('Voer alstublieft een geldig e-mailadres in.')
      .required('E-mail veld is verplicht.'),
    RoleNumber: yup.string()
      .required('Rolnummer veld is verplicht.'),
    Balie: yup.mixed()
      .required('Balie veld is verplicht')
      .oneOf(['Provincie Antwerpen', 'Brussel (Nederlands)', 'West-Vlaanderen', 'Gent', 'Dendermonde', 'Oudenaarde', 'Leuven'], 'Verkeerde selectie')
  })

  const runCreateProspect = (payload) => {
    setLoading(true)
    createProspect(payload)
      .then((response) => {
        if (response.resource) {
          window.location.href = response.resource
        }
      })
      .catch(error => error)
      .finally(() => {
        setLoading(false)
      })
  }

  // Display Loader while fetching prospect
  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <div className='Diplan'>
      <Helmet>
        <title>BA beroep mandaten Ond. Rb.</title>
      </Helmet>
      <Container>
        <h1>Verzekering afsluiten voor mandaten ondernemingsrechtbanken</h1>
        <p className='lead mt-5'>Gelieve onderstaand formulier te vervolledigen om uw verzekering af te sluiten.</p>
        <div>
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true)

              const payload = { ...values }
              setValues(values)

              // Call creactProspect
              runCreateProspect(queryString.stringify({
                ...payload,
                VerdictDate: moment(payload.VerdictDate, 'DD/MM/YYYY').utc(true).toISOString(),
                RegsolNumber: new Date().toISOString(), // TODO Remove for production
                BankruptcyNumber: 'n/a', // TODO Remove for production
                BankruptcyRemainingFunds: 0 // TODO Remove for production
              }))

              resetForm()
              setSubmitting(false)
            }}
          >
            {(
              {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }
            ) => (
                <Form onSubmit={handleSubmit}>
                  <h4>Mandaat</h4>
                  <Form.Row>
                    <Form.Group as={Col} md='6' controlId='CourtAndDepartment'>
                      <Form.Label>Rechtbank en afdeling / Hof *</Form.Label>
                      <Form.Control
                        type='text'
                        name='CourtAndDepartment'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.CourtAndDepartment}
                        className={touched.CourtAndDepartment && errors.CourtAndDepartment ? 'has-error' : null}
                      />
                      {touched.CourtAndDepartment && errors.CourtAndDepartment ? (
                        <p className='error-message'><small>{errors.CourtAndDepartment}</small></p>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col} md='6' controlId='BankruptcyName'>
                      <Form.Label>Naam en vennootschapsvorm van de onderneming/ natuurlijke persoon *</Form.Label>
                      <Form.Control
                        type='text'
                        name='BankruptcyName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.BankruptcyName}
                        className={touched.BankruptcyName && errors.BankruptcyName ? 'has-error' : null}
                      />
                      {touched.BankruptcyName && errors.BankruptcyName ? (
                        <p className='error-message'><small>{errors.BankruptcyName}</small></p>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col} md='6' controlId='VerdictDate'>
                      <Form.Label>Datum uitspraak *</Form.Label>
                      <MaskedFormControl
                        type='text'
                        name='VerdictDate'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.VerdictDate}
                        className={touched.VerdictDate && errors.VerdictDate ? 'has-error' : null}
                        mask='11/11/1111'
                        placeholder='dd/mm/yyyy'
                      />
                      {touched.VerdictDate && errors.VerdictDate ? (
                        <p className='error-message'><small>{errors.VerdictDate}</small></p>
                      ) : null}
                    </Form.Group>
                    <Form.Row as={Col} md='6'>
                      <Form.Label>Adres *</Form.Label>
                      <Form.Group as={Col} md='12' controlId='BankruptcyAddress[StreetNumber]'>
                        <Form.Row>
                          <Form.Group as={Col} md='8'>
                            <Form.Control
                              type='text'
                              name='BankruptcyAddress.StreetName'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.BankruptcyAddress.StreetName}
                              className={getIn(touched, 'BankruptcyAddress.StreetName') && getIn(errors, 'BankruptcyAddress.StreetName') ? 'has-error' : null}
                              placeholder='Straat'
                            />
                          </Form.Group>
                          <Form.Group as={Col} md='4'>
                            <Form.Control
                              type='text'
                              name='BankruptcyAddress.StreetNumber'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.BankruptcyAddress.StreetNumber}
                              className={getIn(touched, 'BankruptcyAddress.StreetNumber') && getIn(errors, 'BankruptcyAddress.StreetNumber') ? 'has-error' : null}
                              placeholder='Huisnr en busnr'
                            />
                          </Form.Group>
                          <Col md='12'>
                            {getIn(touched, 'BankruptcyAddress.StreetName') && getIn(errors, 'BankruptcyAddress.StreetName') ? (
                              <p className='error-message'><small>{getIn(errors, 'BankruptcyAddress.StreetName')}</small></p>
                            ) : null}
                            {getIn(touched, 'BankruptcyAddress.StreetNumber') && getIn(errors, 'BankruptcyAddress.StreetNumber') ? (
                              <p className='error-message'><small>{getIn(errors, 'BankruptcyAddress.StreetNumber')}</small></p>
                            ) : null}
                          </Col>
                          <Form.Group as={Col} md='4'>
                            <Form.Control
                              type='text'
                              name='BankruptcyAddress.PostalCode'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.BankruptcyAddress.PostalCode}
                              className={getIn(touched, 'BankruptcyAddress.PostalCode') && getIn(errors, 'BankruptcyAddress.PostalCode') ? 'has-error' : null}
                              placeholder='Postcode'
                            />
                          </Form.Group>
                          <Form.Group as={Col} md='8'>
                            <Form.Control
                              type='text'
                              name='BankruptcyAddress.City'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.BankruptcyAddress.City}
                              className={getIn(touched, 'BankruptcyAddress.City') && getIn(errors, 'BankruptcyAddress.City') ? 'has-error' : null}
                              placeholder='Gemeente'
                            />
                          </Form.Group>
                          <Col>
                            {getIn(touched, 'BankruptcyAddress.PostalCode') && getIn(errors, 'BankruptcyAddress.PostalCode') ? (
                              <p className='error-message'><small>{getIn(errors, 'BankruptcyAddress.PostalCode')}</small></p>
                            ) : null}
                            {getIn(touched, 'BankruptcyAddress.City') && getIn(errors, 'BankruptcyAddress.City') ? (
                              <p className='error-message'><small>{getIn(errors, 'BankruptcyAddress.City')}</small></p>
                            ) : null}
                          </Col>
                          <Form.Group as={Col} md='12'>
                            <Form.Control
                              type='text'
                              name='BankruptcyAddress.Country'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.BankruptcyAddress.Country}
                              className={getIn(touched, 'BankruptcyAddress.Country') && getIn(errors, 'BankruptcyAddress.Country') ? 'has-error' : null}
                              placeholder='Land'
                            />
                            {getIn(touched, 'BankruptcyAddress.Country') && getIn(errors, 'BankruptcyAddress.Country') ? (
                              <p className='error-message'><small>{getIn(errors, 'BankruptcyAddress.Country')}</small></p>
                            ) : null}
                          </Form.Group>
                        </Form.Row>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group as={Col} md='6' controlId='RoleNumber'>
                      <Form.Label>Rolnummer *</Form.Label>
                      <Form.Control
                        type='text'
                        name='RoleNumber'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.RoleNumber}
                        className={touched.RoleNumber && errors.RoleNumber ? 'has-error' : null}
                      />
                      {touched.RoleNumber && errors.RoleNumber ? (
                        <p className='error-message'><small>{errors.RoleNumber}</small></p>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col} md='6' controlId='KBONumber'>
                      <Form.Label>KBO-nummer (indien van toepassing)</Form.Label>
                      <Form.Control type='text' name='KBONumber' />
                    </Form.Group>
                  </Form.Row>
                  <h4>Informatie mandataris</h4>
                  <Form.Row>
                    <Form.Group as={Col} md='6' controlId='FirstName'>
                      <Form.Label>Voornaam *</Form.Label>
                      <Form.Control
                        type='text'
                        name='FirstName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.FirstName}
                        className={touched.FirstName && errors.FirstName ? 'has-error' : null}
                      />
                      {touched.FirstName && errors.FirstName ? (
                        <p className='error-message'><small>{errors.FirstName}</small></p>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col} md='6' controlId='LastName'>
                      <Form.Label>Achternaam *</Form.Label>
                      <Form.Control
                        type='text'
                        name='LastName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.LastName}
                        className={touched.LastName && errors.LastName ? 'has-error' : null}
                      />
                      {touched.LastName && errors.LastName ? (
                        <p className='error-message'><small>{errors.LastName}</small></p>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col} md='6' controlId='Email'>
                      <Form.Label>E-mail *</Form.Label>
                      <Form.Control
                        type='email'
                        name='Email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Email}
                        className={touched.Email && errors.Email ? 'has-error' : null}
                      />
                      {touched.Email && errors.Email ? (
                        <p className='error-message'><small>{errors.Email}</small></p>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col} md='6' controlId='Balie'>
                      <Form.Label>Hoofdbalie *</Form.Label>
                      <Form.Control
                        as='select'
                        name='Balie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Balie}
                        className={touched.Balie && errors.Balie ? 'has-error' : null}
                      >
                        <option value=''>Selecteer een balie...</option>
                        <option value='Provincie Antwerpen'>Provincie Antwerpen</option>
                        <option value='Brussel (Nederlands)'>Brussel (Nederlands)</option>
                        <option value='West-Vlaanderen'>West-Vlaanderen</option>
                        <option value='Gent'>Gent</option>
                        <option value='Dendermonde'>Dendermonde</option>
                        <option value='Oudenaarde'>Oudenaarde</option>
                        <option value='Leuven'>Leuven</option>
                      </Form.Control>
                      {touched.Balie && errors.Balie ? (
                        <p className='error-message'><small>{errors.Balie}</small></p>
                      ) : null}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                  </Form.Row>
                  <Button type='submit' disabled={isSubmitting}>Verzekering aanvragen</Button>
                </Form>
              )}
          </Formik>
        </div>
      </Container>
    </div>
  )
}

export default Diplad
