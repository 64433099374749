import React from 'react'
import errorImage from './../img/payment_error.svg'
import { Jumbotron, Container } from 'react-bootstrap'

const PageNotFound = () => {
  return (
    <Container>
      <Jumbotron className='text-center'>
        <img className='mb-4' src={errorImage} alt='error' />
        <h1 className='mb-2'>We ontvingen een ongeldig verzoek.</h1>
        <p>Ga terug naar de vorige pagina en probeer het nog eens.</p>
        <p>Bij aanhoudende problemen, contacteer <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a> onder vermelding van uw naam, uw contactgegevens, de naam van het faillissement en bijbehorend regsolnummer.</p>
      </Jumbotron>
    </Container>
  )
}

export default PageNotFound
